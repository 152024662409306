"use client";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { FiPhone } from "react-icons/fi";
import { FaFacebook, FaTwitter, FaLinkedinIn, FaSkype } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header>
      <section
        className="text-sm bg-no-repeat bg-cover overflow-hidden w-full"
        style={{ backgroundImage: 'url("/images/band.jpg")' }}
      >
        <div className="max-w-screen-lg mx-5 lg:mx-auto z-20 py-2 gap-5">
          <div className="flex items-center justify-between">
            {/* Contact Number */}
            <div className="flex items-center gap-2 text-white">
              <FiPhone className="w-4 h-4 lg:h-6 lg:w-6" />
              <Link
                href="tel:+912226734845"
                className="text-sm md:text-lg text-white"
              >
                +91 22 45154705
              </Link>
            </div>

            {/* Social Icons */}
            <div className="flex space-x-3">
              <Link
                href="https://www.facebook.com/pesticides.formulators/"
                className="text-white hover:text-gray-300"
              >
                <FaFacebook className="w-5 h-5 md:w-6 md:h-6" />
              </Link>
              <Link
                href="https://twitter.com/@PMFAI1967"
                className="text-white hover:text-gray-300"
              >
                <FaTwitter className="w-5 h-5 md:w-6 md:h-6" />
              </Link>
              <Link
                href="https://www.linkedin.com/in/pesticides-pmfai-association-of-india-576955161/"
                className="text-white hover:text-gray-300"
              >
                <FaLinkedinIn className="w-5 h-5 md:w-6 md:h-6" />
              </Link>
              <Link
                href="skype:aparna19d?chat"
                className="text-white hover:text-gray-300"
              >
                <FaSkype className="w-5 h-5 md:w-6 md:h-6" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white relative flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-4 bg-brown-500 gray-shadow">
        <nav
          className="max-w-screen-2xl w-full mx-auto pl-4 lg:pl-8  sm:flex sm:items-center sm:justify-between"
          aria-label="Global"
        >
          <div className="flex items-center justify-between">
            <Link href="/" className="flex-none ">
              <Image
                width={430}
                height={72}
                src="/images/logo.png"
                className=" w-52 lg:w-72 h-auto lg:h-auto"
                unoptimized
              />
            </Link>
            <div className="sm:hidden pr-5">
              <button
                onClick={toggleMenu}
                type="button"
                className="p-2 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-800 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-gray-700 dark:text-black dark:hover:bg-white/10 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                aria-controls="navbar-collapse-animation"
                aria-expanded={isMenuOpen}
              >
                {isMenuOpen ? (
                  <svg
                    className="block flex-shrink-0 w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                ) : (
                  <svg
                    className="block flex-shrink-0 w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="3" y1="12" x2="21" y2="12" />
                    <line x1="3" y1="6" x2="21" y2="6" />
                    <line x1="3" y1="18" x2="21" y2="18" />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            id="navbar-collapse-animation"
            className={`hs-collapse ${
              isMenuOpen ? "block" : "hidden"
            } overflow-hidden2 transition-all duration-300 basis-full grow sm:block`}
          >
            <div className="flex flex-col gap-8 mt-5 sm:flex-row sm:items-center sm:justify-center sm:mt-0 sm:pl-5 lg:pl-16 text-black tracking-wider">
              <Link
                href="/"
                className="text-[16px] text-black hover:text-black font-bold"
                aria-current="page"
              >
                Home
              </Link>
              <Link
                href="about-us"
                className="text-[16px] text-black hover:text-black"
              >
                About ICSCE
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/stall-sponsorship/"
              >
                Stall & Sponsorship
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/agenda/"
              >
                Agenda
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/registration/"
              >
                Registration
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/hotel-travel/"
              >
                Hotel & Travel
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/career/"
              >
                Career
              </Link>
              <Link
                className="text-[16px] text-black hover:text-black"
                href="/contact-us/"
              >
                Contact Us
              </Link>
              <Link
                className="w-[140px] h-10 btn-green inline-flex justify-center items-center text-white"
                href="/online-register/"
              >
                Register Now
              </Link>
            </div>
          </div>
        </nav>
      </section>
    </header>
  );
};

export default Header;
